<template>
  <div class="diooo">

  
  <el-dialog
  title="选择银行卡"
  :visible.sync="dialogVisible"
  width="467px"
  :before-close="handleClose"
  center>
  <div class="chooseindex">
    <div class="chooseitem flexbox" v-for="(item,index) in Arr" :key="index">
      <img src="@/assets/img/choosed.png" alt="" class="chooseimg" v-if="item.choose" @click.stop="check(index,item)">
      <img src="@/assets/img/choose.png" alt="" class="chooseimg" v-else @click.stop="check(index,item)">

      <div class="rig">
        <p> <span>姓名：</span>  {{item.name}}</p>
        <p> <span>银行卡号：</span>  {{item.cardNo}}</p>
        <p> <span>开户行：</span>  {{item.bankName}}</p>
      </div>
    </div>
    
  </div>
  <div class="btnbox" @click.stop="touse">使用</div>
</el-dialog>
</div>
</template>

<script>
import apiUrl from '@/api/main'
export default {
  name: 'index',
  components: {},
  props: {
   
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      dialogVisible:false,
      Arr:[],
      id:'', // 选中id
    }
  },

  mounted() {
    
  },

  methods: {
    open(e){
      this.dialogVisible = true;
      this.id = e;
      this.getData();
    },
    handleClose(){
      this.dialogVisible = false;
    },
    check(num){
      // this[`choose${num}`] = !this[`choose${num}`];
      this.Arr.forEach((item,i) => {
        if(num == i){
          item.choose = !item.choose;
          this.id = item.id;
        } else {
          item.choose = false
        }
      });
    },
    // 获取银行卡
    getData() {
      let data = {
        userId: localStorage.getItem('userId'),
        userType:0
      }
      apiUrl.lsUserBanklist(data).then((res) => {
        if (res.code == 0) {
          res.rows.forEach(element => {
            if(this.id && element.id == this.id){
              element.choose = true;
            }else {
              element.choose = false;
            }
          });
          this.Arr = res.rows;
        } 
      })
    },
    // 去使用
    touse(){
      this.dialogVisible = false;
      this.$emit('bankinfo', this.id);
    },

  }
}
</script>

<style lang="scss">
.diooo{

  .chooseindex{
    padding: 0 50px;
    .chooseitem{
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 27px;
      padding-top: 27px;
      .chooseimg{
        width: 17px;
        height: 17px;
        margin-right: 40px;
      }
      .rig{
        p{
          font-size: 15px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 17px;
          span{
            width: 75px;
            font-size: 15px;
            color: #333333;
            font-weight: 400;
          }
        }
        p:nth-last-child(1){
          margin-bottom: 0px;
        }
      }
    }
    .chooseitem:nth-child(1){
      padding-top: 0px;
    }
    .chooseitem:nth-last-child(1){
      border-bottom: 0px solid transparent;
      padding-bottom: 0px;
    }

  }
  .btnbox{
      width: 187px;
      height: 47px;
      line-height: 47px;
      border-radius: 23px;
      margin: 0 auto;
      margin-top: 67px;
    }
  }
</style>