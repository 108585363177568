<template>
    <div class="tixianindex">
        <div class="mainbox">
          <p class="toptitle flexbox">我的钱包 <img src="@/assets/img/right.png" alt=""> <span>提现</span></p>
          <div class="tititle">提现金额</div>
          <div class="tixianmoney">
            <el-input v-model="tixianmoney" placeholder="请输入提现金额"></el-input>
            元
          </div>
          <div class="ket">可提现金额{{allmoney}}元， <span @click.stop="tixianmoney=allmoney">全部提现</span></div>
          <p class="tititle">提现至</p>

          <div class="daobox flex">
            <div class="zfbbox flex">
              <img src="@/assets/img/choosed.png" alt="" class="chooseimg" v-if="choose1" @click.stop="check1">
              <img src="@/assets/img/choose.png" alt="" class="chooseimg" v-else @click.stop="check1">
              <div class="zfbright"> 
                <div class="daotop flexbox">
                  <img src="@/assets/img/zfb.png" alt="">
                  支付宝
                </div>
                <el-input v-model="zfbNo" placeholder="请输入支付宝账号"></el-input>
              </div>
            </div>
            <div class="cardbox flex">
              <img src="@/assets/img/choosed.png" alt="" class="chooseimg" v-if="choose2" @click.stop="check2">
              <img src="@/assets/img/choose.png" alt="" class="chooseimg" v-else @click.stop="check2">
              <div class="zfbright"> 
                <div class="daotop flex_between">
                  <div class="flexbox">
                    <img src="@/assets/img/yhk.png" alt=""> 银行卡
                  </div>
                  <span @click.stop="addCard" v-if="cardshowtext">+常用</span>
                </div>
                <el-input v-model="bank.name" placeholder="请输入姓名"></el-input>
                <el-input v-model="bank.cardNo" placeholder="请输入银行卡号" @blur="inputChange"></el-input>
                <el-input v-model="bank.bankName" placeholder="请输入开户行"></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="hejibox flex_between">
          <div class="heji">合计： <span>{{allmoney || 0}}</span></div>
          <div class="btnbox" @click.stop="openModel">立即提现</div>
        </div>

        <card ref="cardshow" @bankinfo="getbankinfo"></card>
    </div>
</template>

<script>
import card from '@/components/card'

import apiUrl from '@/api/main'
export default {
    components: {card},
    data() {
        return {
          tixianmoney:'', // 可输入的提现金额
          allmoney: '', // 全部可提现金额
          zfbNo:'', // 支付宝账号
          choose1:false,
          choose2:false,
          current:'', // 1支付宝 2银行卡
          bank:{}, // 银行卡
          cardshowtext:false, // 如果银行卡列表有数据，就true
          addid:'', //银行卡信息id
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.getData();
      this.getDatalist();
    },
    methods: {
      check1(){
        this.choose1 = !this.choose1;
        this.choose2 = false;
        this.current = 1;
        this.bank = {}
      },
      check2(){
        this.choose2 = !this.choose2;
        this.choose1 = false;
        this.current = 2;
        this.zfbNo = '';
      },
      addCard(){
        this.check2();
        if(this.addid){
          this.$refs.cardshow.open(this.addid);
        } else {
          this.$refs.cardshow.open();
        }
        
      },
      // 获取银行卡列表
      getDatalist() {
        let data = {
          userId: localStorage.getItem('userId'),
          userType:0
        }
        apiUrl.lsUserBanklist(data).then((res) => {
          if (res.code == 0) {
            if(res.rows.length>0){
              this.cardshowtext = true;

              res.rows.forEach(element => {
                if(element.id == this.addid){
                  this.bank = element;
                }
              });

            } else {
              this.cardshowtext = false;
            }
          } 
        })
      },
      // 获取总提现金额
      getData() {
          let data = {
            userId: localStorage.getItem('userId')
          }
          apiUrl.lsUsermoneylog(data).then((res) => {
            if (res.code == 0) {
              this.allmoney = res.money;
            } 
          })
        },
      // 银行卡号
      inputChange(){
        const inputRule = /[^\d]/g;
        if(inputRule.test(this.bank.cardNo)){
          this.$message({
            message:'请检查银行卡号',
            type:'error'
          });
          return
        }
      },
      // 提现
      openModel(){
        let money = this.tixianmoney - 0;
        if(money <= 0){
          this.$message({
            message:'提现金额不能小于0',
            type:'error'
          });
          return
        } else if(money > (this.allmoney)){
          this.$message({
            message:'提现金额不能大于余额',
            type:'error'
          });
          return;
        }
        if(!this.current){
          this.$message({
            message:'请选择提现方式',
            type:'error'
          });
          return;
        }
        if(this.current == 1 && !this.zfbNo){
          this.$message({
            message:'请输入支付宝账号',
            type:'error'
          });
          return;
        }

        if(this.current == 2 && (!this.bank.name||!this.bank.cardNo||!this.bank.bankName)){
          this.$message({
            message:'请输入银行卡提现信息',
            type:'error'
          });
          return;
        }

        let data = {
					userId: localStorage.getItem('userId'),
					money:this.tixianmoney,
					type:this.current==1?0:1,//0支付宝 1银行卡
					userType:0,
				}

        if (this.current == 1) {
					data['zfbNo']=this.zfbNo;
				}
				if (this.current == 2) {
					data['name']=this.bank.name;
					data['cardNo']=this.bank.cardNo;
					data['bankName']=this.bank.bankName;
				}

        apiUrl.addlsTixian(data).then((res) => {
          this.$message({
            message:res.msg,
            type:'success'
          });
          if(res.code == 0){
            setTimeout(() => {
              this.$router.go(-1);
            })
          }
        })





        
      },
      // 银行卡信息
      getbankinfo(e){
        this.addid = e;
        this.choose2 = true;
        this.choose1 = false;
        this.current = 2;
        this.zfbNo = '';

        this.getDatalist();
      },

    },
  
    }
</script>
<style lang='scss' >
.tixianindex{
  background-color: #f8f8f8;
  .mainbox{
    width: 1216px;
    // height: 538px;
    background: #FFFFFF;
    border-radius: 13px;
    padding: 20px 40px 64px;
    .toptitle{
        font-size: 16px;
        color: #777777;
        span{
          font-size: 16px;
          color: #333333;
          margin-left: 7px;
        }
        img{
          width: 20px;
          height: 20px;
        }
    }
    .tititle{
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      margin-top: 40px;
    }
    .tixianmoney{
      font-size: 15px;
      color: #333333;
      margin-top: 13px;
      .el-input{
        width: 300px;
      }
    }
    .ket{
      font-size: 14px;
      color: #777777;
      margin-top: 10px;
      span{
        font-size: 14px;
        color: #305BFE;
        margin-left: 3px;
        cursor: pointer;
      }
    }
    .daobox{
      margin-top: 20px;
      .zfbbox{
        width: 360px;
        margin-right: 40px;
      }
      .cardbox{
        width: 360px;
      }
      .chooseimg{
        width: 20px;
        height: 20px;
        margin-right: 32px;
        margin-top: 6px;
      }
      .zfbright{
        .daotop{
          font-size: 15px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 16px;
          img{
            width: 32px;
            height: 32px;
            margin-right: 16px;
          }
          span{
            cursor: pointer;
            font-size: 16px;
            color: #305BFE;
          }
        }
        .el-input{
          width: 360px;
          margin-bottom: 13px;
          // border: 1px solid #E6E6E6;
          border-radius: 9px;
          height: 56px;
          line-height: 56px;
        }
        .el-input__inner{
          height: 56px;
          line-height: 56px;
          border-radius: 9px;
        }
        
      }

    }
  }
  .hejibox{
    width: 1216px;
    height: 96px;
    background: #FFFFFF;
    border-radius: 13px 13px 0px 0px;
    margin-top: 33px;
    padding: 0 40px;
    .heji{
      font-size: 18px;
      color: #333333;
      margin-right: 3px;
      span{
        font-size: 24px;
        font-weight: 500;
        color: #F34933;
      }
    }
    .btnbox{
      width: 240px;
      height: 56px;
      line-height: 56px;
      border-radius: 28px;
      font-size: 20px;
    }
  }
}

</style>